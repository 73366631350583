import { createRouter, createWebHashHistory } from 'vue-router'
import Main from '../views/VMainPage/VMain.vue'
import Release from '../views/VRelease/VIndex.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/releases/:id',
    name: 'release.show',
    component: Release
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
