<template>
  <div>
      <main class="main-content">
          <div class="container">
              <div class="house__content">
                  <h1 class="house__content-title">Поддерживаем и разрабатываем
                    цифровые продукты и сервисы
                  </h1>
                  <div class="house__content-box">
                    <v-project-card 
                      v-for="project in projects"
                      :key="project.id"
                      :project-id="project.id"
                      :title="project.name"
                      :image="project.image"
                      :update-count="project.update_count"
                      :version-count="project.version_count"
                    />
                  </div>
                </div>
          </div>
    </main>
  </div>
</template>

<script>
import VProjectCard from '@/views/VMainPage/components/VProjectCard.vue';

export default {
  data() {
    return {
      projects: [],
    }
  },
  methods: {
    getProject() {
      const res = fetch('https://release.inhouse.kz/api/projects')
                    .then(response => response.json())
                    .then(data => {
                        this.projects = data.data.project
                    })
      return res
    }
  },
  mounted() {
    this.getProject()
  },
  components: {
    VProjectCard
  }
}
</script>
