<template>
   <div class="house__item">
      <h6 class="house__item-title">{{ title }}</h6>
      <ul class="house__item-list">
         <li><span>{{ updateCount }}</span> обновлений</li>
         <li><span>{{ versionCount }}</span> версии</li>
      </ul>
      <!-- <div class="house__item-link">
         <a href="#" v-for="tag in tags" :key="tag.id">#<span>{{ tag.name }}</span></a>
      </div> -->
      <router-link :to="{ name: 'release.show', params: { id: projectId }}" class="house__item-btn">
         <span>Подробнее</span>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M4.47998 11.98H19.47" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
         <path d="M13.4829 5.98801L19.5199 12L13.4829 18.012" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
      </router-link>
      <div class="house__item-img">
         <img :src="image" alt="">
      </div>
   </div>  
</template>

<script>

export default {
   name: "VProjectCard",
   props: {
      projectId: Number,
      title: String,
      updateCount: Number,
      versionCount: Number,
      image: {
            type: String,
            default: "",
      },
      release: String,
   },
   data() {
      return {
         id: this.$route.params.id
      };
   },
   components: {  }
}
</script>

<style>

</style>