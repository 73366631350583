<template>
  <div class="house__card-block"  v-if="isOpen">
    <div class="house__card" :class="{'show': isOpen}">
      <div class="house__card-inner">
        <div class="house__card-top">
          <img class="house__card-square" src="../../../assets/img/buginholding-logo.png">
          <div class="house__card-info">
            <h6 class="house__card-title">{{ name }} in <span class="title-blue">{{ projectName }}</span></h6>
            <div class="house__task-subtitle">
              <div class="house__task-process">
                <p>{{ date }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="house__card-body">
          <div class="house__card-left">
            <div class="house__process" v-html="text">

            </div>
            <div class="house__files" v-if="gallery.length > 0">
              <h3>Файлы</h3>
              <div class="house__files-body">
                <div class="house__files-item" v-for="(galleryItem, idx) in gallery" :data-index="idx" :key="idx">
                  <expandable-image :src="galleryItem" />
                </div>
              </div>

            </div>
          </div>
          <div class="house__card-right">
            <div class="house__card-heading">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.41683 6.33333C8.0835 6.99999 8.0835 8 7.41683 8.66666C6.75016 9.33333 5.75016 9.33333 5.0835 8.66666C4.41683 8 4.41683 6.99999 5.0835 6.33333C5.75016 5.66666 6.75016 5.66666 7.41683 6.33333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.8333 5.58333C15.6667 6.41666 15.6667 7.75 14.8333 8.5C14 9.25 12.6667 9.33333 11.9167 8.5C11.1667 7.66666 11.0833 6.33333 11.9167 5.58333C12.6667 4.83333 14 4.83333 14.8333 5.58333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.0835 15V14.1667C17.0835 12.75 16.0002 11.6667 14.5835 11.6667H12.0835C10.6668 11.6667 9.5835 12.75 9.5835 14.1667V15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.08341 11.6667H5.41675C4.00008 11.6667 2.91675 12.75 2.91675 14.1667V15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>Участники задачи</p>
            </div>
            <div class="house__participant-list">
              <div class="house__participant-item" v-for="user in users" :key="user.id">
                <img class="house__first-circle" :src="user.avatar">
                <p>{{ user.name }}</p>
              </div>
            </div>
            <div class="house__card-heading">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.91675 12.0833L8.75008 6.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.25 12.0833L12.0833 6.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.1251 7.70832H7.29175" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.7083 10.625H6.875" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98818 15.8333L9.41076 17.2559C9.73619 17.5813 10.2638 17.5813 10.5892 17.2559L12.0118 15.8333H15C16.3807 15.8333 17.5 14.714 17.5 13.3333V5C17.5 3.61929 16.3807 2.5 15 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V13.3333C2.5 14.714 3.61929 15.8333 5 15.8333H7.98818Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>Хештеги</p>
            </div>
            <div class="house__card-link">
              <div v-for="tag in tags" :key="tag.id">#{{ tag.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <button class="house__card-btn" @click="$emit('update:isOpen', false), bodyScroll()">
        <img src="../../../assets/img/house-card-close.svg" alt="">
      </button>
    </div>
    <div class="house__card-close"  @click="$emit('update:isOpen', false), bodyScroll()"></div>
  </div>
</template>

<script>
import ExpandableImage from '@/components/ExpandableImage.vue'

export default {
  data() {
    return {

    }
  },
  props: {
    isOpen: Boolean,
    releaseId: Number,
    name: String,
    projectName: String,
    text: String,
    gallery: Array,
    date: String,
    users: Array,
    tags: Array,
  },
  components: {
    ExpandableImage
  },
  methods: {
    bodyScroll() {
      document.body.style.overflow = 'auto';
    }

  }
}
</script>

<style>

</style>