<template>
  <nav>
    <v-header />
  </nav>
  <router-view/>
</template>

<script>
import '@/assets/style/style.scss'
import '@/assets/style/style-media.scss'
import '@/assets/style/font.scss'
import VHeader from '@/components/VHeader.vue'

export default {
  data() {
    return {
      
    }
  },
  components: {
    VHeader
  }
}
</script>

<style lang="scss">

</style>
