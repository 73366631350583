<template>
   <header class="header">
      <div class="container">
         <div class="header__inner">
               <a href="#" class="header-house__logo">
                  <img src="../assets/img/logo.svg" alt="">
               </a>
               <div class="header__block">
                  <div class="header__search-block">
                     <div class="header__search">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M19.2492 19.2502L15.2617 15.2627" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                            
                        <input type="search" placeholder="Поиск" v-model="search" @keyup="getResults()">
                     </div>
                     <div class="header__search-result" v-if="isActive">
                        <div class="house__task-item" 
                           v-for="release in searchResults.slice().reverse()"
                           :key="release.id"
                        >
                           <div class="house__left-block">
                              <div class="house__item-info">
                                 <h6 class="house__task-title" 
                                    @click="openModal(release)">{{ release.name }}</h6>
                                 <div class="house__task-subtitle">
                                 <div class="house__task-process">
                                    <p>{{ release.status }} <span>{{ release.date }}</span></p>
                                 </div>
                                 </div>
                              </div>
                           </div>   
                        </div>
                     </div>
                  </div>
                  <div class="menu__item" :class="{ 'show': showSearch }">
                     <div class="header__mobile-search-block">
                        <div class="header__mobile-search">
                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M19.2492 19.2502L15.2617 15.2627" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <input type="search" placeholder="Поиск" v-model="search" @keyup="getResults()">
                        </div>
                        <div class="header__search-result" v-if="isActive">
                           <div class="house__task-item" 
                              v-for="release in searchResults" 
                              :key="release.id"
                           >
                              <div class="house__left-block">
                                 <div class="house__item-info">
                                    <h6 class="house__task-title" 
                                       @click="openModal(release)">{{ release.name }}</h6>
                                    <div class="house__task-subtitle">
                                    <div class="house__task-process">
                                       <p>{{ release.status }} <span>{{ release.date }}</span></p>
                                    </div>
                                    </div>
                                 </div>
                              </div>   
                           </div>
                        </div>
                     </div>
                     <ul class="nav__item">
                           <li @click="openMenu()"><router-link to="/">Продукты</router-link></li>
                           <!-- <li><a href="#">Календарь</a></li> -->
                     </ul>
                  </div>
                  <a href="https://work.inhouse.kz/admin/login" class="header__login-btn" target="_blank">
                     <span>Войти</span>
                  </a>
                  <div class="burger" :class="{ 'active': showMenu }" @click="openMenu()">
                  </div>
               </div>
         </div>
         <v-release-modal 
               :release-id="releaseId"
               :name="releaseName"
               :date="releaseDate"
               :project-name="projectName"
               :text="releaseText"
               v-model:isOpen="show"
               :users="users"
               :tags="tags"
         />
      </div>
   </header> 
</template>

<script>
import VReleaseModal from '@/views/VRelease/components/VReleaseModal.vue'

export default {
   data() {
      return {
         show: false,
         showSearch: false,
         showMenu: false,
         isActive: false,
         projects: [],
         search: '',
         searchResults: [],
         releaseId: null,
         releaseName: '',
         releaseDate: '',
         projectName: '',
         releaseText: '',
         userAvatars: [],
         users: [],
         tags: [],
      }
   },
   components: {
      VReleaseModal
   },
   methods: {
      getResults() {
         this.isActive = true
         const res = fetch(`https://release.inhouse.kz/api/releases?`+ new URLSearchParams({
                        name: this.search,
                     }))
                     .then(response => response.json())
                     .then(data => this.searchResults = data.data.releases )
         return res
         
      },
      getProject() {
         const id = this.releaseId
         const res = fetch(`https://release.inhouse.kz/api/releases/${id}`)
                        .then(response => response.json())
                        .then(data => { 
                                    this.projectName = data.data.project_name
                                    this.releaseDate = data.data.date
                                    this.releaseName = data.data.name
                                    this.releaseText = data.data.text
                                    this.users = data.data.users
                                    this.tags = data.data.tags
                              } )
         return res
      },
      openMenu() {
         this.showMenu = !this.showMenu
         this.showSearch = !this.showSearch
      },
      openModal(release) {
         this.show = !this.show
         
         this.releaseId = release.id
         this.search = ''
         this.getProject();
      }
   },
   created() {
      window.addEventListener('click', (e) => {
         if(e.target.className !== 'header__search-result') {
            this.isActive = false;
         }
      })
   },
   mounted() {
    
   },
   watch: {
      
   }
}
</script>

<style>

</style>