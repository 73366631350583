<template>
   <main class="main-content">
      <div class="container">
            <div class="house__content" >
               <h1 class="house__content-title">{{ projectName }}</h1>
               <div class="house__task-top">
                  <ul class="house__item-list">
                  <li><span>{{updateCount}}</span> обновлений</li>
                  <li><span>{{versionCount}}</span> версии</li>
                  </ul>
                  <div>
<!--                     <ul>-->
<!--                        <li v-for="all in filteredData"> {{ all.date }}</li>-->
<!--                     </ul>-->
                  </div>
                  <div class="house__task-calendar">
                     <div class="house__task-time">
                        <label>Дата с</label>
                        <input type="text" onfocus="(this.type = 'date')" onblur="if(!this.value) this.type='text'" v-model="date_from">
                     </div>
                     <div class="house__task-time">
                        <label>Дата до</label>
                        <input type="text" onfocus="(this.type = 'date')" onblur="if(!this.value) this.type='text'" v-model="date_to"> 

                     </div>
                  </div>
               </div>
               <div class="house__task-list">
                  <div class="house__task-item" 
                     v-for="release in releases.slice().reverse()"
                     :key="release.id"
                       @click="openModal(release)"
                  >
                     <div class="house__left-block">
                        <img class="house__item-square" src="../../assets/img/buginholding-logo.png">
                        <div class="house__item-info">
                           <h6 class="house__task-title">{{ release.name }}</h6>
                           <div class="house__task-subtitle">
                           <div class="house__task-process">
                              <p>{{ release.status }} <span>{{ release.date }}</span></p>
                           </div>
                           <div class="house__task-link">
                              <div href="#" v-for="tag in release.tags" :key="tag.id">
                                 #{{ tag.name }}
                              </div>
                           </div>
                           </div>
                        </div>
                     </div>
                     <div class="house__right-block">
                        <img class="house__item-circle first-circle" v-for="user in release.users" :key="user.id" :src="user.avatar">
                     </div>

                  </div>
               </div>
            </div>
            <v-release-modal 
               :release-id="releaseId"
               :name="releaseName"
               :date="releaseDate"
               :project-name="projectName"
               :text="releaseText"
               :gallery="gallery"
               v-model:isOpen="show"
               :users="users"
               :tags="tags"
            />
      </div>
   </main>
</template>

<script>
import VReleaseModal from '@/views/VRelease/components/VReleaseModal.vue'

export default {
   data() {
      return {
         show: false,
         releases: [],
         releaseId: null,
         releaseName: '',
         releaseDate: '',
         projectName: '',
         releaseText: '',
         gallery: [],
         updateCount: '',
         versionCount: '',
         userAvatars: [],
         users: [],
         tags: [],
         results: [],
         date_from: '',
         date_to: '',
         startDate: null,
         endDate: null,
         date2: {
            all: [
               { "date": '2023-01-02'},
               { "date": '2023-02-15'},
               { "date": '2023-02-20'},
               { "date": '2023-01-18'},
               { "date": '2023-01-24'},
            ]
         },
      }
   },
   components: {
      VReleaseModal
   },
   methods: {
      getProject() {
         const id = this.releaseId
         const res = fetch(`https://release.inhouse.kz/api/releases/${id}`)
                        .then(response => response.json())
                        .then(data => { 
                                    this.projectName = data.data.project_name
                                    this.releaseDate = data.data.date
                                    this.releaseName = data.data.name
                                    this.releaseText = data.data.text
                                    this.gallery = data.data.gallery
                                    this.users = data.data.users
                                    this.tags = data.data.tags
                              } )
         return res
      },
      getReleases() {
         const id = this.$route.params.id
         // const date_from = this.date_from
         // const date_to = this.date_to
         const res = fetch(`https://release.inhouse.kz/api/releases?`+ new URLSearchParams({
                           project_id: id,
                           // date_from: date_from,
                           // date_to: date_to,
                        }))
                        .then(response => response.json())
                        .then(data => { this.projectName = data.data.project[0].name
                                        this.updateCount = data.data.project[0].update_count
                                        this.versionCount = data.data.project[0].version_count
                                        this.releases = data.data.releases
                                     })
         return res
      },
      openModal(release) {
         this.show = !this.show
         this.releaseId = release.id
         this.getProject();
         if (this.show) {
           document.body.style.overflow = 'hidden';
         }

      },

   },
   mounted() {
      this.getReleases();

   },
   computed: {
      // filteredData() {
      //    var vm = this
      //    var startDate = vm.startDate;
      //    var endDate = vm.endDate;
      //    return _.filter(vm.date2.all, function(data) {
      //       if ((_.isNull(startDate) && _.isNull(endDate))) {
      //          return true
      //       }
      //       else {
      //          var date = data.date;
      //          return (date >= startDate && date <= endDate);
      //       }
      //    })
      // }
   },
}
</script>