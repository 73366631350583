<template>
  <div
    class="expandable-image"
    :class="{ expanded: expanded }"
    @click="expanded = true"
  >
    <i
      v-if="expanded"
      class="close-button"

    >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_422_2)">
      <g filter="url(#filter0_d_422_2)">
      <path d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3108 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2459 15.6915 34.5325 11.5607 31.4859 8.51408C28.4394 5.46751 24.3085 3.75414 20 3.75V3.75ZM20 33.75C17.2805 33.75 14.6221 32.9436 12.3609 31.4327C10.0997 29.9218 8.33737 27.7744 7.29666 25.2619C6.25596 22.7494 5.98366 19.9847 6.51421 17.3175C7.04476 14.6503 8.35432 12.2003 10.2773 10.2773C12.2003 8.35431 14.6503 7.04475 17.3175 6.5142C19.9848 5.98366 22.7494 6.25595 25.2619 7.29666C27.7744 8.33736 29.9218 10.0997 31.4327 12.3609C32.9436 14.6221 33.75 17.2805 33.75 20C33.7459 23.6455 32.2959 27.1404 29.7182 29.7182C27.1404 32.2959 23.6455 33.7459 20 33.75V33.75ZM25.8906 24.1094C26.1254 24.3464 26.257 24.6664 26.257 25C26.257 25.3336 26.1254 25.6536 25.8906 25.8906C25.6517 26.1216 25.3324 26.2508 25 26.2508C24.6677 26.2508 24.3483 26.1216 24.1094 25.8906L20 21.7656L15.8906 25.8906C15.6517 26.1216 15.3324 26.2508 15 26.2508C14.6677 26.2508 14.3483 26.1216 14.1094 25.8906C13.8747 25.6536 13.743 25.3336 13.743 25C13.743 24.6664 13.8747 24.3464 14.1094 24.1094L18.2344 20L14.1094 15.8906C13.9101 15.6478 13.8082 15.3394 13.8236 15.0256C13.839 14.7118 13.9706 14.4149 14.1928 14.1928C14.4149 13.9706 14.7118 13.839 15.0256 13.8236C15.3394 13.8082 15.6478 13.91 15.8906 14.1094L20 18.2344L24.1094 14.1094C24.3523 13.91 24.6606 13.8082 24.9744 13.8236C25.2882 13.839 25.5851 13.9706 25.8072 14.1928C26.0294 14.4149 26.161 14.7118 26.1764 15.0256C26.1918 15.3394 26.09 15.6478 25.8906 15.8906L21.7656 20L25.8906 24.1094Z" fill="white"/>
      </g>
      </g>
      <defs>
      <filter id="filter0_d_422_2" x="-0.25" y="0.75" width="40.5" height="40.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_2"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_422_2" result="shape"/>
      </filter>
      <clipPath id="clip0_422_2">
      <rect width="40" height="40" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    </i>
    <i
      v-if="!expanded"
      class="expand-button"
    >
      <svg
        style="width:35px;height:35px"
        viewBox="0 0 35 35"
      >
        <path
          fill="#000000"
          d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z"
        />
      </svg>
    </i>
    <img v-bind="$attrs"/>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      expanded: false,
      closeButtonRef: null
    }
  },
  methods: {
    closeImage (event) {
      this.expanded = false
      event.stopPropagation()
    },
    freezeVp (e) {
      e.preventDefault()
    }
  },
  watch: {
    expanded (status) {
      this.$nextTick(() => {
        if (status) {
          this.cloned = this.$el.cloneNode(true)
          this.closeButtonRef = this.cloned.querySelector('.close-button')
          this.closeButtonRef.addEventListener('click', this.closeImage)
          document.body.appendChild(this.cloned)
          document.body.style.overflow = 'hidden'
          this.cloned.addEventListener('touchmove', this.freezeVp, false);
          setTimeout(() => {
            this.cloned.style.opacity = 1
          }, 0)
        } else {
          this.cloned.style.opacity = 0
          this.cloned.removeEventListener('touchmove', this.freezeVp, false);
          setTimeout(() => {
            this.closeButtonRef.removeEventListener('click', this.closeImage)
            this.cloned.remove()
            this.cloned = null
            this.closeButtonRef = null
            document.body.style.overflow = 'auto'
          }, 250)
        }
      })
    }
  }
}
</script>

<style>
.expandable-image {
  position: relative;
  transition: 0.25s opacity;
  cursor: zoom-in;
}
body > .expandable-image.expanded {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  opacity: 0;
  padding-bottom: 0 !important;
  cursor: default;
}
body > .expandable-image.expanded > img {
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
body > .expandable-image.expanded > .close-button {
  display: block;
}
.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  display: none;
  cursor: pointer;
}
.expand-button {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 10px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  opacity: 0;
  transition: 0.2s opacity;
}
.expandable-image:hover .expand-button {
  opacity: 1;
}
.expand-button svg {
  width: 20px;
  height: 20px;
}
.expand-button path {
  fill: #FFF;
}
.expandable-image img {
  width: 100%;
}
</style>